<template>
    <div class="StoreMarketingOne">
        <div class="StoreMarketingOneForm">
            <el-form style="margin-left: 30px;" label-width="80px" :model="form" ref="form" :rules="rules">
                <el-form-item label="创建名称" prop="marketing_name">
                    <el-input style="width: 300px;" v-model="form.marketing_name"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="category_id">
                    <el-select v-model="form.category_id" placeholder="请选择" style="width: 300px;" @change="selectCategoryName">
                        <el-option
                                v-for="item in classifyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="storePromotion">
                    <div class="storePromotionTitle" style="position: relative">
                        <span class="title"><b style="color: #FF0000;position: absolute;left: -9px">*</b>活动促销策划案</span>
                        <span class="message">（支持扩展名：rar和zip,文件不超过10M）</span>
                    </div>
                    <div class="storePromotionUpload">
                        <div class="uploadBtn" onclick="storeInput.click()">选择文件</div>
                        <input
                                ref="modelInput"
                                multiple
                                type="file"
                                id="storeInput"
                                @change="uploadStore($event)"
                                style="display:none"
                                accept=".rar,.zip"
                        />
                        <span v-if="storeName" class="uploadFile">{{storeName}}<i @click="deleStoreFile" class="iconfont fileIcon">&#xe724;</i></span>
                    </div>
                </div>
<!--                <div class="storePromotion">-->
<!--                    <div class="storePromotionTitle" style="position: relative">-->
<!--                        <span class="title"><b style="color: #FF0000;position: absolute;left: -9px">*</b>套装促销</span>-->
<!--                        <span class="message">（支持扩展名：rar和zip,文件不超过10M）</span>-->
<!--                    </div>-->
<!--                    <div class="storePromotionUpload">-->
<!--                        <div class="uploadBtn" onclick="suitInput.click()">选择文件</div>-->
<!--                        <input-->
<!--                                ref="modelInput"-->
<!--                                multiple-->
<!--                                type="file"-->
<!--                                id="suitInput"-->
<!--                                @change="uploadSuit($event)"-->
<!--                                style="display:none"-->
<!--                                accept=".rar,.zip"-->
<!--                        />-->
<!--                        <span v-if="suitName" class="uploadFile">{{suitName}}<i @click="deleSuit" class="iconfont fileIcon">&#xe724;</i></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="storePromotion">-->
<!--                    <div class="storePromotionTitle" style="position: relative">-->
<!--                        <span class="title"><b style="color: #FF0000;position: absolute;left: -9px">*</b>优惠券</span>-->
<!--                        <span class="message">（支持扩展名：rar和zip,文件不超过10M）</span>-->
<!--                    </div>-->
<!--                    <div class="storePromotionUpload" style="margin-bottom: 30px">-->
<!--                        <div class="uploadBtn" onclick="discountsInput.click()">选择文件</div>-->
<!--                        <input-->
<!--                                ref="modelInput"-->
<!--                                multiple-->
<!--                                type="file"-->
<!--                                id="discountsInput"-->
<!--                                @change="uploadDiscounts($event)"-->
<!--                                style="display:none"-->
<!--                                accept=".rar,.zip"-->
<!--                        />-->
<!--                        <span v-if="discountsName" class="uploadFile">{{discountsName}}<i @click="deleDiscounts" class="iconfont fileIcon">&#xe724;</i></span>-->
<!--                    </div>-->
<!--                </div>-->
                <el-form-item label="备注" style="margin-top: 30px">
                    <el-input type="textarea" :rows="4" style="width: 300px;" v-model="form.remark"></el-input>
                </el-form-item>
            </el-form>
            <div class="toBtn">
                <el-button class="btn1" @click="toBack">上一步</el-button>
                <el-button class="btn1" @click="toNext">下一步</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StoreMarketingOne",
        data(){
            return {
                form:{
                    marketing_name:'',
                    category_name:'',
                    category_id:'',
                    remark:''
                },
                rules:{
                    marketing_name: [
                        { required: true, message: '请输入活动名称', trigger: 'blur' },
                        { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                    ],
                    category_id: [
                        { required: true, message: '请选择活动区域', trigger: 'change' }
                    ]
                },
                classifyOptions:[],
                storeFile:{},
                storeFileUrl:{
                    name:'',
                    url:''
                },
                storeName:'',
                suitFile:{},
                suitFileUrl:{
                    name:'',
                    url:''
                },
                suitName:'',
                discountsFile:{},
                discountsFileUrl:{
                    name:'',
                    url:''
                },
                discountsName:'',
                resData:{}
            }
        },
        created() {
            this.getCategoryList();
            this.getEditData();
        },
        methods: {
            getEditData(){
                console.log(this.$route.query);
                if(this.$route.query.rowId){
                    this.$http.axiosGetBy(this.$api.showStoreMarket, {id:this.$route.query.rowId}, res=>{
                        // console.log(128,res)
                        if(res.code === 200){
                            this.resData = res.data.data[0];
                            this.form.marketing_name = this.resData.marketing_name;
                            this.form.category_name = this.resData.category_name;
                            this.form.category_id = this.resData.category_id;
                            this.form.remark = this.resData.remark;
                            this.storeName = this.resData.store_file.name;
                            this.storeFileUrl.name = this.resData.store_file.name;
                            this.storeFileUrl.url = this.resData.store_file.url;
                            // this.suitName = this.resData.suit_file.name;
                            // this.suitFileUrl.name = this.resData.suit_file.name;
                            // this.suitFileUrl.url = this.resData.suit_file.url;
                            // this.discountsName = this.resData.discount_file.name;
                            // this.discountsFileUrl.name = this.resData.discount_file.name;
                            // this.discountsFileUrl.url = this.resData.discount_file.url;
                        } else {
                            this.$message({
                                type:'warning',
                                duration:1000,
                                message:res.msg,
                                onClose:()=>{
                                    this.$router.go(-1)
                                }
                            })
                        }
                    })
                }
            },
            getCategoryList(){
              this.$http.axiosGet(this.$api.categoryList, res=>{
                  if(res.code === 200){
                      this.classifyOptions = res.data
                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            //上传店铺促销
            uploadStore(event){
                let fileSize = event.target.files[0].size;
                let modelName = event.target.files[0].name;
                let isLimite10M = fileSize / 1024 / 1024 < 10;
                let limiteFileType = ['rar','zip'];
                if(!limiteFileType.includes(modelName.split(".")[modelName.split(".").length - 1].toLowerCase())){
                    this.$message.warning('文件格式错误，请重新上传正确文件格式！');
                    return;
                }
                if(!isLimite10M){
                    this.$message.warning('上传文件大小不能超过10M！')
                }
                this.storeFile = event.target.files[0];
                this.storeName = modelName;
                let formData = new FormData();
                formData.append('file',this.storeFile);
                this.$http.axiosPost(this.$api.uploadSmFile, formData, res=>{
                    console.log(166,res)
                    if(res.code === 200){
                        this.storeFileUrl.url = res.data.url;
                        this.storeFileUrl.name = res.data.name;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            deleStoreFile(){
                this.storeFile = {};
                this.storeName = '';
            },
            uploadSuit(event){
                let fileSize = event.target.files[0].size;
                let modelName = event.target.files[0].name;
                let isLimite10M = fileSize / 1024 / 1024 < 10;
                let limiteFileType = ['rar','zip'];
                if(!limiteFileType.includes(modelName.split(".")[modelName.split(".").length - 1].toLowerCase())){
                    this.$message.warning('文件格式错误，请重新上传正确文件格式！');
                    return;
                }
                if(!isLimite10M){
                    this.$message.warning('上传文件大小不能超过10M！')
                }
                this.suitFile = event.target.files[0];
                this.suitName = modelName;
                let formData = new FormData();
                formData.append('file',this.suitFile);
                this.$http.axiosPost(this.$api.uploadSmFile, formData, res=>{
                    console.log(166,res)
                    if(res.code === 200){
                        this.suitFileUrl.url = res.data.url;
                        this.suitFileUrl.name = res.data.name;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            deleSuit(){
                this.suitFile = {};
                this.suitName = ''; 
            },  
            uploadDiscounts(event){ 
                let fileSize = event.target.files[0].size;  
                let modelName = event.target.files[0].name; 
                let isLimite10M = fileSize / 1024 / 1024 < 10;  
                let limiteFileType = ['rar','zip']; 
                if(!limiteFileType.includes(modelName.split(".")[modelName.split(".").length - 1].toLowerCase())){
                    this.$message.warning('文件格式错误，请重新上传正确文件格式！');
                    return;
                }
                if(!isLimite10M){
                    this.$message.warning('上传文件大小不能超过10M！')
                }
                this.discountsFile = event.target.files[0];
                this.discountsName = modelName;
                let formData = new FormData();
                formData.append('file',this.discountsFile);
                this.$http.axiosPost(this.$api.uploadSmFile, formData, res=>{
                    console.log(166,res)
                    if(res.code === 200){
                        this.discountsFileUrl.url = res.data.url;
                        this.discountsFileUrl.name = res.data.name;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectCategoryName(val){
                this.classifyOptions.map(item=>{
                    if(item.id === val){
                        this.form.category_name = item.name
                    }
                })
            },
            deleDiscounts(){
                this.discountsFile = {};
                this.discountsName = '';
            },
            toBack(){
                this.$router.go(-1)
            },
            toNext(){
                if(this.form.marketing_name && this.form.category_id && this.storeName /*&& this.suitName && this.discountsName*/){
                    let formOne = JSON.stringify(this.form);
                    if(this.form.marketing_name.length>=3&&this.form.marketing_name.length<=20){
                        if(this.$route.query.rowId){
                            this.$router.push({
                                path: '/admin/operatedata/storemarketingtwo',
                                query:{
                                    oneForm:formOne,
                                    storeFile: JSON.stringify(this.storeFileUrl),
                                    // suitFile: this.suitFileUrl,
                                    // discountsFile: this.discountsFileUrl,
                                    resData:JSON.stringify(this.resData)
                                }
                            })
                        } else {
                            this.$router.push({
                                path: '/admin/operatedata/storemarketingtwo',
                                query:{
                                    oneForm:formOne,
                                    storeFile: JSON.stringify(this.storeFileUrl),
                                    // suitFile: this.suitFileUrl,
                                    // discountsFile: this.discountsFileUrl
                                }
                            })
                        }
                    }else{
                        this.$message.warning('长度在 3 到 20 个字符')
                    }
                } else {
                    this.$message.warning('必填项不能为空！')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .StoreMarketingOne{
        .StoreMarketingOneForm{
            .storePromotion{
                margin-top: 28px;
                padding-left: 13px;
                .storePromotionTitle{
                    font-size: 14px;
                    .title{
                        color: #13131B;
                    }
                    .message{
                        color: #878994;
                    }
                }
                .storePromotionUpload{
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    .uploadBtn{
                        background: #1122D8;
                        color: #ffffff;
                        width: 80px;
                        height: 30px;
                        font-size: 12px;
                        border-radius: 2px;
                        text-align: center;
                        line-height: 30px;
                        cursor: pointer;
                    }
                    .uploadFile{
                        font-size: 12px;
                        color: #13131B;
                        font-weight: 400;
                        margin-left: 20px;
                        .fileIcon{
                            color: #FF0000;
                            font-size: 14px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .toBtn{
                display: flex;
                justify-content: center;
                margin-top: 40px;
                .btn1{
                    width: 100px;
                    height: 30px;
                    line-height: 0;
                }
                .btn1:nth-child(1){
                    background: #ffffff;
                    border-color: #1122D8;
                    color: #1122D8;
                }
                .btn1:nth-child(2){
                    background: #1122D8;
                    border-color: #1122D8;
                    color: #ffffff;
                }
            }
        }
    }
</style>